@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #1c1917;
  --text: #fafaf9;
  --text-light: #a8a29e;
  --font-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --neon-color: #9373f3;
}

body {
  font-family: var(--font-sans);
  background-color: var(--background);
  color: var(--text);
}

@keyframes tubeFlicker {
  0%, 100% {
    opacity: 0.98;
    filter: brightness(1);
  }
  95% {
    opacity: 0.98;
    filter: brightness(1);
  }
  96% {
    opacity: 0.95;
    filter: brightness(0.9);
  }
  97% {
    opacity: 0.98;
    filter: brightness(1);
  }
}

@keyframes subtleGlow {
  0%, 100% {
    filter: drop-shadow(0 0 2px rgba(147, 115, 243, 0.9))
           drop-shadow(0 0 4px rgba(147, 115, 243, 0.7))
           drop-shadow(0 0 8px rgba(147, 115, 243, 0.5));
  }
  50% {
    filter: drop-shadow(0 0 3px rgba(147, 115, 243, 1))
           drop-shadow(0 0 6px rgba(147, 115, 243, 0.8))
           drop-shadow(0 0 12px rgba(147, 115, 243, 0.6));
  }
}

.neon-glow {
  animation: tubeFlicker 8s infinite, subtleGlow 4s ease-in-out infinite;
  will-change: filter, opacity;
}
